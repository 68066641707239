<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
// import branchComponent from "@/components/branchComponent.vue";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "วางบิล",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "วางบิล",
      items: [
        {
          text: "การเงิน",
          active: true,
        },
        {
          text: "วางบิล",
          active: false,
        },
      ],
      errormessage: "กรุณากรอกข้อมูลให้ครบ",
      rowsSelect: [],
      loading: false,
      submitform: false,
      selected: "",
      rowsTotalpages: "",
      totalPage: "",
      totalRecord: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      rowsCus: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      isHidden: false,
      branch: "",
      billingId: "",
      selectMode: "multi",
      userBranchId: "",

      billingDate: "",
      dueDate: "",
      filter: {
        roCode: "",
        customer: "",
        invCode: "",
        licensePlate: "",
        userBranchId: "",
      },
      formCreate: {
        branch: "",
        cus: "",
        billingDate: "",
      },
      optionBranch: [],
      userBranch: "",
      urlPrint: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "billingCode",
          sortable: true, 
          label: "เลขที่วางบิล",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "สกุล",
        },
        {
          key: "paymentDate",
          sortable: true,
          label: "วันที่สร้าง",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
          class: "text-end",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "action",
          sortable: false,
          label: appConfig.action,
        },
      ],
      overlayFlag: false,
    };
  },
  validations: {
    formCreate: {
      branch: {
        required,
      },
      cus: {
        required,
      },
      billingDate: {
        required,
      },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
    this.getDataCustomer();
  },
  created() {
    // this.select()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/billing", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.filter.userBranchId.branchId != null
                ? this.filter.userBranchId.branchId
                : this.userBranchId,
            year: this.year,
            month: this.month,
            billingCode: this.filter.invCode,
            status: this.status,
            customer: this.filter.customer,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    getDataCustomer: function() {
      if (this.formCreate.branch != null) {
        useNetw
          .get("api/customer", {
            params: {
              page: 1,
              perPage: 100,
              "branchId[]":
                this.formCreate.branch.branchId != null
                  ? this.formCreate.branch.branchId
                  : this.userBranchId,
            },
          })
          .then((response) => {
            this.rowsCus = response.data.data;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {});
      }
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows;
    },
    confirmSubmitBilling: function() {
      //   this.roId = roId;
      useNetw
        .post("api/billing/store", {
          branchId: this.formCreate.branch.branchId,
          cusId: this.formCreate.cus.cusId,
          billingDate: this.formCreate.billingDate,
          dueDate: this.dueDate != null ? this.dueDate : undefined,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.formCreate.branch = null;
          this.formCreate.cus = null;
          this.formCreate.billingDate = null;
          this.dueDate = null;
          this.getData();
            this.$router.push({
              name: "detail-billing",
              params: { billingId: response.data.billingId },
            });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalCreateBilling.hide();
        });
    },
    deleteBilling(billingId) {
      this.billingId = billingId;
      this.overlayFlag = true;
      useNetw
        .delete("api/billing/delete", {
          data: {
            billingId: this.billingId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
          this.getDataCreate();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      this.getData();
    },
    // showRo() {
    //   this.getDataRo();
    // },
    alertDelete(billingId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบใบวางบิลหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteBilling(billingId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    getBillingPrint(billingId) {
      this.billingId = billingId;
      this.loading = true;
      useNetw
        .get("api/billing/print", {
          params: {
            billingId: this.billingId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          // window.open(this.urlPrint);
          window.location = (this.urlPrint);
          // commit;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.formCreate.$invalid != true) {
        this.confirmSubmitBilling();
      }
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ออกใบกำกับภาษี:
                        <multiselect
                          v-model="type"
                          label="receiptName"
                          type="search"
                          :options="receipt"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        สาขา:
                        <multiselect
                          v-model="filter.userBranchId"
                          label="nameTh"
                          type="search"
                          :options="optionBranch"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขที่ใบวางบิล:
                        <b-form-input
                          v-model="filter.invCode"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขที่ใบแจ้งซ่อม:
                        <b-form-input
                          v-model="filter.roCode"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div> -->
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ป้ายทะเบียน:
                        <b-form-input
                          v-model="filter.licensePlate"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ชื่อลูกค้า:
                        <b-form-input
                          v-model="filter.customer"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn float-end m-1"
                    variant="info"
                    type="submit"
                    @click="handleSearch"
                  >
                    <i class="uil-search-alt"></i>
                    ค้นหา
                  </b-button>
                  <b-button
                    class="btn float-end m-1"
                    variant="primary"
                    v-b-modal.modalCreateBilling
                  >
                    <i class="uil-plus"></i>
                    สร้าง
                  </b-button>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      แสดงผล&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp;รายการ
                    </label>
                  </div>
                </div>
              </div>
              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
                <div class="table-responsive mb-0">
                  <b-table
                    :items="rows"
                    :fields="fields"
                    :current-page="1"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                  >
                    <template #cell(index)="rows">
                      {{ rows.index + 1 }}
                    </template>
                    <template #cell(status)="rowRo">
                      <span
                        class="badge bg-warning font-size-14"
                        v-if="rowRo.item.status === 'DFT'"
                      >
                        ร่าง
                      </span>
                      <span
                        class="badge bg-success font-size-14"
                        v-if="rowRo.item.status === 'APV'"
                      >
                        ยืนยันออกใบกำกับ
                      </span>
                      <span
                        class="badge bg-danger font-size-14"
                        v-if="rowRo.item.status === 'CAN'"
                      >
                        ยกเลิก
                      </span>
                    </template>
                    <template v-slot:cell(action)="rowsBilling">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <router-link
                            href="javascript:void(0);"
                            class="px-2 text-primary"
                            v-b-tooltip.hover
                            :to="{
                              name: 'detail-billing',
                              params: { billingId: rowsBilling.item.billingId },
                            }"
                            title="View"
                          >
                            <i class="uil uil-pen font-size-18"></i>
                          </router-link>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-danger"
                            v-b-tooltip.hover
                            title="Delete"
                            @click="alertDelete(rowsBilling.item.billingId)"
                          >
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-success"
                            v-b-tooltip.hover
                            title="Print"
                            @click="getBillingPrint(rowsBilling.item.billingId)"
                          >
                            <i class="uil-print font-size-18"></i>
                          </a>
                        </li>
                      </ul>
                    </template>
                  </b-table>
                </div>
              </b-skeleton-wrapper>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rows.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <!-- modallllllllllllllllllllllllllllllllllllllllll -->
    <b-modal
      ref="modalCreateBilling"
      id="modalCreateBilling"
      title="สร้างใบวางบิล"
      hide-footer
      centered
      size="xl"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3 position-relative">
                  สาขา:
                  <multiselect
                    v-model="formCreate.branch"
                    label="nameTh"
                    type="search"
                    :options="optionBranch"
                    :show-labels="false"
                    @input="getDataCustomer()"
                    open-direction="bottom"
                    placeholder=""
                    :class="{
                      'is-invalid': submitform && $v.formCreate.branch.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                  <div
                    v-if="submitform && $v.formCreate.branch.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.formCreate.branch.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3 position-relative">
                  ชื่อลูกค้า:
                  <multiselect
                    v-model="formCreate.cus"
                    label="nameTh"
                    type="search"
                    :options="rowsCus"
                    :custom-label="customLabel"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    :class="{
                      'is-invalid': submitform && $v.formCreate.cus.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                  <div
                    v-if="submitform && $v.formCreate.cus.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.formCreate.cus.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3 position-relative">
                  <code> * </code>วันที่สร้างใบวางบิล:
                  <date-picker
                    v-model="formCreate.billingDate"
                    format="YYYY-MM-DD"
                    value-type="format"
                    :class="{
                      'is-invalid':
                        submitform && $v.formCreate.billingDate.$error,
                    }"
                  ></date-picker>
                  <div
                    v-if="submitform && $v.formCreate.billingDate.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.formCreate.billingDate.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  วันที่ครบกำหนด:
                  <date-picker
                    v-model="dueDate"
                    format="YYYY-MM-DD"
                    value-type="format"
                  ></date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
            <b-button
              class="btn float-end"
              variant="success"
              type="submit"
              @click="tooltipForm"
            >
              บันทึก
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
